<template>
    <div v-if="$wdconfig.default.social.whatsapp.active === 'true'">
        <b-tooltip :show.sync="whatsapp_tooltip" :target="`#btn-whatsapp${_uid}`" placement="left" container="btn-whatsapp-container">
            {{ $wdconfig.default.social.whatsapp.title }}
        </b-tooltip>
        <a :href="$wdconfig.default.social.whatsapp.link" class="btn-whatsapp" :id="`btn-whatsapp${_uid}`" target="_blank" rel="nofollow" :aria-label="$wdconfig.default.social.whatsapp.title">
            <svg version="1.1" fill="#FFF" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                <g>
                    <g>
                        <path d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
                            C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
                            c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
                            c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
                            c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
                            c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
                            c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
                            c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
                            c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"/>
                    </g>
                </g>
            </svg>
        </a>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                whatsapp_tooltip: true
            }
        },
        mounted() {
            if (process.client) {
                setTimeout(() => {
                    this.whatsapp_tooltip = false
                }, 6000);
            }
        }
    }
</script>
