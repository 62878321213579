<template>
    <div>
        <div class="menu-mobile" v-show="isVisible">
            <div class="d-flex justify-content-between text-center">
                <div v-for="item in menu" :key="item.name" @click="verifyItem(item)" class="mt-1 flex-fill">
                    <b-button variant="link" :to="item.link" class="px-1">
                        <div class="position-relative">
                            <b-icon :icon="item.icon" class="item-icon" :class="{'menu-mobile-active-icon': isEqual(item.value)}"></b-icon>
                            <div v-if="item.value == 'cart' && $store.state.order.order.quantidade_total > 0" class="menu-mobile-items-cart">
                                {{ $store.state.order.order.quantidade_total }}
                            </div>
                        </div>
                        <div class="item-name" :class="{'active': isEqual(item.value)}">
                            {{ item.name }}
                        </div>
                    </b-button>
                </div>
            </div>
        </div>
        <div class="openned-menu" v-if="active_menu == 'categories'">
            <div class="openned-menu-title">
                Categorias
            </div>
            <div>
                <CategoriesMobile/>
            </div>
        </div>

        <div class="openned-menu" v-if="active_menu === 'menu'">
            <div class="openned-menu-title">
                Menu
            </div>
            <div>
                <MenuMobile/>
            </div>
        </div>
    </div>
</template>
<script>
    import CategoriesMobile from "~/components/CategoriesMobile";
    import MenuMobile from "~/components/MenuMobile/MenuMobile";

    export default {
        components: {
            CategoriesMobile,
            MenuMobile
        },
        head() {
            return {
                bodyAttrs: {
                    class: this.overlay ? 'mobile-openned-menu' : ''
                }
            }
        },
        data() {
            return {
                menu: [
                    {
                        name: 'Home',
                        icon: 'house-fill',
                        value: 'home',
                        link: '/',
                        overlay: false
                    },
                    {
                        name: 'Categorias',
                        icon: 'list-ul',
                        value: 'categories',
                        overlay: true
                    },
                    {
                        name: 'Minha conta',
                        icon: 'person-circle',
                        value: 'account',
                        link: '/conta/meus-dados/',
                        overlay: false
                    },
                    {
                        name: 'Carrinho',
                        icon: 'cart',
                        value: 'cart',
                        link: '/carrinho',
                        overlay: false
                    },
                    {
                        name: 'Menu',
                        icon: 'list',
                        value: 'menu',
                        overlay: true
                    },
                ],
                active_menu: '',
                overlay: false
            }
        },
        methods: {
            verifyItem(item) {
                if (this.active_menu === item.value) {
                    this.active_menu = ''
                    this.overlay = false
                } else {
                    this.active_menu = item.value
                    this.overlay = item.overlay
                }
            },
            isEqual(item) {
                return this.active_menu === item
            }
        },
        computed: {
          isVisible() {
              if (this.$route.path.indexOf('/produto/') === 0 || this.$route.path === '/carrinho' ||  this.$route.path === '/checkout') {
                  return false
              }
              return true
            }
        },
        watch: {
            $route(newvalue) {
                this.active_menu = ''
                this.overlay = false
            }
        }
    }
</script>
