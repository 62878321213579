import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6dbe2caa = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _cfae142e = () => interopDefault(import('../pages/busca.vue' /* webpackChunkName: "pages/busca" */))
const _9daf8c04 = () => interopDefault(import('../pages/cadastro.vue' /* webpackChunkName: "pages/cadastro" */))
const _ef13a362 = () => interopDefault(import('../pages/carrinho.vue' /* webpackChunkName: "pages/carrinho" */))
const _ddb46f84 = () => interopDefault(import('../pages/categoria.vue' /* webpackChunkName: "pages/categoria" */))
const _50c79768 = () => interopDefault(import('../pages/categorias/index.vue' /* webpackChunkName: "pages/categorias/index" */))
const _18918ece = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _531b34fd = () => interopDefault(import('../pages/conta/index.vue' /* webpackChunkName: "pages/conta/index" */))
const _f704cb16 = () => interopDefault(import('../pages/contato.vue' /* webpackChunkName: "pages/contato" */))
const _1563811c = () => interopDefault(import('../pages/duvidas-frequentes.vue' /* webpackChunkName: "pages/duvidas-frequentes" */))
const _a6907308 = () => interopDefault(import('../pages/email-password-change.vue' /* webpackChunkName: "pages/email-password-change" */))
const _60fbe52a = () => interopDefault(import('../pages/esitef_cancel.vue' /* webpackChunkName: "pages/esitef_cancel" */))
const _cb6b3dec = () => interopDefault(import('../pages/esitef_failure.vue' /* webpackChunkName: "pages/esitef_failure" */))
const _5994e9fa = () => interopDefault(import('../pages/esitef_success.vue' /* webpackChunkName: "pages/esitef_success" */))
const _8a954b22 = () => interopDefault(import('../pages/lista-de-presentes/index.vue' /* webpackChunkName: "pages/lista-de-presentes/index" */))
const _f8908618 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4050ea0f = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _ea55f268 = () => interopDefault(import('../pages/politica-de-cookies.vue' /* webpackChunkName: "pages/politica-de-cookies" */))
const _a8b30516 = () => interopDefault(import('../pages/politica-de-entrega.vue' /* webpackChunkName: "pages/politica-de-entrega" */))
const _f292d91a = () => interopDefault(import('../pages/politica-de-privacidade.vue' /* webpackChunkName: "pages/politica-de-privacidade" */))
const _73a0a8f9 = () => interopDefault(import('../pages/quem-somos.vue' /* webpackChunkName: "pages/quem-somos" */))
const _48efbbdc = () => interopDefault(import('../pages/register_confirmation.vue' /* webpackChunkName: "pages/register_confirmation" */))
const _d32325fa = () => interopDefault(import('../pages/regra-de-cupons.vue' /* webpackChunkName: "pages/regra-de-cupons" */))
const _99b10d0e = () => interopDefault(import('../pages/trocas-e-devolucoes.vue' /* webpackChunkName: "pages/trocas-e-devolucoes" */))
const _25849a8c = () => interopDefault(import('../pages/checkout/confirmacao.vue' /* webpackChunkName: "pages/checkout/confirmacao" */))
const _3c332dcc = () => interopDefault(import('../pages/conta/lista-de-presentes.vue' /* webpackChunkName: "pages/conta/lista-de-presentes" */))
const _614d6f39 = () => interopDefault(import('../pages/conta/meus-dados.vue' /* webpackChunkName: "pages/conta/meus-dados" */))
const _5c2d1efc = () => interopDefault(import('../pages/conta/pedidos/index.vue' /* webpackChunkName: "pages/conta/pedidos/index" */))
const _e8949600 = () => interopDefault(import('../pages/conta/senha.vue' /* webpackChunkName: "pages/conta/senha" */))
const _63a78e74 = () => interopDefault(import('../pages/lista-de-presentes/listas/index.vue' /* webpackChunkName: "pages/lista-de-presentes/listas/index" */))
const _56511428 = () => interopDefault(import('../pages/lista-de-presentes/listas/imprimir/_code.vue' /* webpackChunkName: "pages/lista-de-presentes/listas/imprimir/_code" */))
const _2e9bd907 = () => interopDefault(import('../pages/conta/pedidos/_order_code.vue' /* webpackChunkName: "pages/conta/pedidos/_order_code" */))
const _60122c80 = () => interopDefault(import('../pages/lista-de-presentes/listas/_code.vue' /* webpackChunkName: "pages/lista-de-presentes/listas/_code" */))
const _5f379252 = () => interopDefault(import('../pages/blog/_post.vue' /* webpackChunkName: "pages/blog/_post" */))
const _7298365a = () => interopDefault(import('../pages/informacoes/_alias.vue' /* webpackChunkName: "pages/informacoes/_alias" */))
const _1c64ea72 = () => interopDefault(import('../pages/lp/_alias.vue' /* webpackChunkName: "pages/lp/_alias" */))
const _2520ed55 = () => interopDefault(import('../pages/produto/_produto.vue' /* webpackChunkName: "pages/produto/_produto" */))
const _8bdfcc46 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _74d13340 = () => interopDefault(import('../pages/_cms_page_alias.vue' /* webpackChunkName: "pages/_cms_page_alias" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _6dbe2caa,
    name: "blog"
  }, {
    path: "/busca",
    component: _cfae142e,
    name: "busca"
  }, {
    path: "/cadastro",
    component: _9daf8c04,
    name: "cadastro"
  }, {
    path: "/carrinho",
    component: _ef13a362,
    name: "carrinho"
  }, {
    path: "/categoria",
    component: _ddb46f84,
    name: "categoria"
  }, {
    path: "/categorias",
    component: _50c79768,
    name: "categorias"
  }, {
    path: "/checkout",
    component: _18918ece,
    name: "checkout"
  }, {
    path: "/conta",
    component: _531b34fd,
    name: "conta"
  }, {
    path: "/contato",
    component: _f704cb16,
    name: "contato"
  }, {
    path: "/duvidas-frequentes",
    component: _1563811c,
    name: "duvidas-frequentes"
  }, {
    path: "/email-password-change",
    component: _a6907308,
    name: "email-password-change"
  }, {
    path: "/esitef_cancel",
    component: _60fbe52a,
    name: "esitef_cancel"
  }, {
    path: "/esitef_failure",
    component: _cb6b3dec,
    name: "esitef_failure"
  }, {
    path: "/esitef_success",
    component: _5994e9fa,
    name: "esitef_success"
  }, {
    path: "/lista-de-presentes",
    component: _8a954b22,
    name: "lista-de-presentes"
  }, {
    path: "/login",
    component: _f8908618,
    name: "login"
  }, {
    path: "/logout",
    component: _4050ea0f,
    name: "logout"
  }, {
    path: "/politica-de-cookies",
    component: _ea55f268,
    name: "politica-de-cookies"
  }, {
    path: "/politica-de-entrega",
    component: _a8b30516,
    name: "politica-de-entrega"
  }, {
    path: "/politica-de-privacidade",
    component: _f292d91a,
    name: "politica-de-privacidade"
  }, {
    path: "/quem-somos",
    component: _73a0a8f9,
    name: "quem-somos"
  }, {
    path: "/register_confirmation",
    component: _48efbbdc,
    name: "register_confirmation"
  }, {
    path: "/regra-de-cupons",
    component: _d32325fa,
    name: "regra-de-cupons"
  }, {
    path: "/trocas-e-devolucoes",
    component: _99b10d0e,
    name: "trocas-e-devolucoes"
  }, {
    path: "/checkout/confirmacao",
    component: _25849a8c,
    name: "checkout-confirmacao"
  }, {
    path: "/conta/lista-de-presentes",
    component: _3c332dcc,
    name: "conta-lista-de-presentes"
  }, {
    path: "/conta/meus-dados",
    component: _614d6f39,
    name: "conta-meus-dados"
  }, {
    path: "/conta/pedidos",
    component: _5c2d1efc,
    name: "conta-pedidos"
  }, {
    path: "/conta/senha",
    component: _e8949600,
    name: "conta-senha"
  }, {
    path: "/lista-de-presentes/listas",
    component: _63a78e74,
    name: "lista-de-presentes-listas"
  }, {
    path: "/lista-de-presentes/listas/imprimir/:code",
    component: _56511428,
    name: "lista-de-presentes-listas-imprimir-code"
  }, {
    path: "/conta/pedidos/:order_code",
    component: _2e9bd907,
    name: "conta-pedidos-order_code"
  }, {
    path: "/lista-de-presentes/listas/:code?",
    component: _60122c80,
    name: "lista-de-presentes-listas-code"
  }, {
    path: "/blog/:post",
    component: _5f379252,
    name: "blog-post"
  }, {
    path: "/informacoes/:alias?",
    component: _7298365a,
    name: "informacoes-alias"
  }, {
    path: "/lp/:alias?",
    component: _1c64ea72,
    name: "lp-alias"
  }, {
    path: "/produto/:produto?",
    component: _2520ed55,
    name: "produto-produto"
  }, {
    path: "/",
    component: _8bdfcc46,
    name: "index"
  }, {
    path: "/:cms_page_alias",
    component: _74d13340,
    name: "cms_page_alias"
  }, {
    path: "/categoria/:category/:subcategory?/:subcategory2?",
    component: _ddb46f84
  }, {
    path: "/produto/:category?/:subcategory?/:subcategory2?/:produto",
    component: _2520ed55
  }, {
    path: "/cadastro/confirmacao",
    component: _48efbbdc
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
