<template>
    <img v-lazy="imageUrl(photo)" :alt="name" :title="name" v-bind="$attrs" :class="image_class" :src="imgNotLoad">
</template>
<script>
    import utils from "~/mixins/utils";

    export default ({
        props: ['photo', 'name', 'image_class'],
        mixins: [
            utils
        ],
        data() {
            return {
                imgNotLoad: 'data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjU3NXB4IiBoZWlnaHQ9IjM4NXB4IiB2aWV3Qm94PSIwIDAgNTc1IDM4NSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiPgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSI1NzUiIGhlaWdodD0iMzg1IiBmaWxsPSIjZjFmMWYxIiBzdHJva2Utd2lkdGg9IjAiICAvPgo8L3N2Zz4='
            }
        }
    })
</script>
