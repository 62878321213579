import Cookies from 'js-cookie';

export default {
    methods: {
        lgpdCheck(url, $event) {
            if (url) {
                switch (url.trim().toLowerCase()) {
                    case '#cookiesconfig':
                    case '#cookies_config':
                    case '#lgpdconfig':
                    case '#lgpd_config':
                        this.ShowCookies()
                        $event.preventDefault()
                        break;
                }
            }
        },
        ShowCookies() {
            Cookies.set('lgpd', false)
            sessionStorage.setItem("lgpd_config", false)
            this.$nuxt.$emit('openModal', true)
        }
    }
}
