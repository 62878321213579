import Cookies from 'js-cookie';

export default {
    methods: {
        LgpdResponseAnalytics() {
            const lgpd_analytics = Cookies.get('lgpd_load_analytics')
            if (lgpd_analytics === undefined || lgpd_analytics === 'true') {
                return true;
            }
            return false;
        },
        LgpdResponseMarketing() {
            const lgpd_marketing = Cookies.get('lgpd_load_marketing')
            if (lgpd_marketing === undefined || lgpd_marketing === 'true') {
                return true;
            }
            return false;
        }
    }
}
