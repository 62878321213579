import Cookies from 'js-cookie';

export default {
    methods: {
        fbqEvent(track, event, params, eventID = null, counter = 10) {
            if (eventID === null) {
                eventID = Date.now().toString() + Math.floor(Math.random() * 1000).toString()
            }
            this.sendClient(track, event, params, eventID);
            this.sendServer(track, event, params, eventID);
        },
        sendClient(track, event, params, eventID, counter = 10) {
            if (this.LgpdResponseMarketing()) {
                setTimeout(() => {
                    if (counter <= 0) {
                        return
                    }
                    counter--;
                    try {
                        fbq(track, event, params, eventID);
                    } catch (e) {
                        setTimeout(() => {
                            this.sendClient(track, event, params, eventID, counter);
                        }, 500);
                    }
                }, 500);
            }
        },
        sendServer(track, name, data = {}, event_id = null) {
            if (this.$wdconfig.modules.fcapi.active) {
                let params = {}
                params['event_source_url'] = window.location.href || ''
                params['event_name'] = name
                params['event_id'] = event_id
                params['user_data'] = {
                    client_user_agent: window.navigator.userAgent || ''
                }
                params['custom_data'] = data
                this.$axios.$post('/?p=facebook_conversions_api', params)
                .then(response => {
                    console.log(response.data)
                })
                .catch(error => {
                    console.error(error)
                })
            }
        },
        LgpdResponse() {
            let lgpd_marketing = Cookies.get('lgpd_load_marketing')
            if (this.$wdconfig.default.google_tag_manager && lgpd_marketing === 'true' ) {
                return true;
            }
            return false;
        },
        FacebookPixelAddToCart(value, id, name, quantity = 1) {
            if (this.LgpdResponse()) {
                this.fbqEvent('track', 'AddToCart', {
                    currency: 'BRL',
                    content_type: 'product',
                    value: value,
                    contents: {
                        id: id,
                        quantity: quantity,
                        itemprice: value,
                        name: name,
                    }
                })
            }
        },
        FacebookViewContent(category, name, type, id, value) {
            if (this.LgpdResponse()) {
                this.fbqEvent('track', 'ViewContent', {
                    content_name: name,
                    content_category: category,
                    content_type: type,
                    content_ids: [id],
                    value: value,
                    currency: 'BRL'
                })
            }
        }
    }
}
