<template>
    <div v-if="!isAdult">
        <b-modal id="modalAge" ref="modal-age" class="mt-4" hide-header hide-footer static-backdrop no-close-on-backdrop>
            <div class="modal-body">
                <div class="modal-title d-flex text-center justify-content-center align-items-center">
                    <h3 v-if="!isMinor">Você tem mais de 18 anos?</h3>
                    <h3 v-else>Você precisa ter mais de 18 anos para acessar o site!</h3>
                </div>
                <div v-if="!isMinor" class="modal-buttons d-flex text-center justify-content-center align-items-center pt-3 pb-4">
                    <button @click="handleNo()" class="btn-default mx-2 btn-square px-5 text-uppercase">não</button>
                    <button @click="handleYes()" class="btn-default mx-2 btn-square px-5 text-uppercase">sim</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<style lang="scss" scoped>
    #modalAge {
        .modal-body{
            padding-top: 50px;
        }
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
<script>
    import Cookies from 'js-cookie';
    export default {
        data() {
            return {
                isMinor: false,
                isAdult: false
            };
        },
        mounted() {
            if(Cookies.get('age_controller') === undefined && this.$wdconfig.modules.age_control.age_controller){
                this.$refs['modal-age'].show();
            }
        },
        methods: {
            handleYes() {
                this.isMinor = false;
                this.isAdult = true;
                Cookies.set('age_controller', true)
            },
            handleNo() {
                this.isMinor = true;
            },
        },
    };
</script>
