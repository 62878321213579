<template>
    <div class="collapse-menu">
        <template v-if="typeof menu_showcase === 'object' && menu_showcase.length">
            <nuxt-link :to="'/lp/' + item.alias" v-for="item in menu_showcase" :key="item.id" class="collapse-menu-btn collapse-menu-name w-100 d-flex justify-content-between">
                <div>{{item.name}}</div>
            </nuxt-link>
        </template>
        <CategoriesMobileItem :item="item" v-for="item in $store.state.categories" :key="item.code" parent_route="/categoria"/>
    </div>
</template>

<script>
    import CategoriesMobileItem from "~/components/CategoriesMobileItem";

    export default {
        components: {CategoriesMobileItem},
        data() {
            return {
                menu_showcase: []
            }
        },
        async fetch() {
            this.menu_showcase = this.$store.state.menu_showcase
        }
    }
</script>
