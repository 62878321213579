<template>
    <li class="categories-main">
        <div class="cl-item" v-for="item in items.slice(0, limit_items)" :key="item.code">
            <nuxt-link :to="item.route" class="cate-name d-flex justify-content-between align-items-center" :class="{'hover-category': item.code === hover_category}">
                {{ item.name }}
                <div v-if="item.subitems && item.subitems.length">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10px" height="8px"
                         viewBox="0 0 46 46" style="enable-background:new 0 0 46 46;" xml:space="preserve">
                        <g>
                            <path d="M11.003,40.094c-1.338,1.352-1.338,3.541,0,4.893c1.336,1.35,3.506,1.352,4.844,0l19.15-19.539
                                c1.338-1.352,1.338-3.543,0-4.895L15.847,1.014c-1.338-1.352-3.506-1.352-4.844,0s-1.338,3.541-0.002,4.893L26.706,23
                                L11.003,40.094z"/>
                        </g>
                    </svg>
                </div>
            </nuxt-link>
            <div v-if="item.subitems && item.subitems.length" @mouseover="hover_category = item.code" @mouseleave="hover_category = null">
                <MegaMenuView :item="item" :maxCols="3"/>
            </div>
        </div>
        <div class="cl-item" v-if="items.length > limit_items">
            <nuxt-link to="/categorias" class="cate-name d-flex justify-content-between align-items-center">
                + categorias
            </nuxt-link>
        </div>
    </li>
</template>

<script>
    import MegaMenuView from "~/components/MegaMenu/MegaMenuView";

    export default {
        components: {
            MegaMenuView
        },
        props: {
            items: {
                type: Array,
                default: () => []
            },
            tag: {
                type: String,
                default: 'div'
            }
        },
        data() {
            return {
                hover_category: null,
                limit_items: 10
            }
        }
    }
</script>
