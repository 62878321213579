export const merge = (target = {}, source = {}) => {
    const copyTarget = JSON.parse(JSON.stringify(target))
    const copySource = JSON.parse(JSON.stringify(source))
    Object.keys(copySource).forEach(key => {
        if (typeof copySource[key] === "object" && !Array.isArray(copySource[key])) {
            copyTarget[key] = merge(copyTarget[key], copySource[key])
        } else {
            copyTarget[key] = copySource[key]
        }
    });
    return copyTarget
}
