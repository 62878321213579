<template>
    <div class="head">
        <b-container class="py-3">
            <b-row class="align-items-center">
                <b-col cols="1" class="d-md-none" v-if="$route.path !== '/'">
                    <a @click="$router.go(-1)">
                        <svg fill="#fff" height="30px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="60px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "/></svg>
                    </a>
                </b-col>
                <b-col :cols="$route.path.includes('/produto') ? 6 : 8" md="4" lg="3" class="d-flex justify-content-center pr-md-1 ml-3 ml-md-0">
                    <nuxt-link to="/">
                        <img :src="imageUrl($wdconfig.default.logo)" :alt="$wdconfig.default.title" :title="$wdconfig.default.title" :width="$wdconfig.modules.general.logo_width" :height="$wdconfig.modules.general.logo_height" class="logo-image">
                    </nuxt-link>
                </b-col>

                <b-col cols="2" md="2" lg="3" class="d-flex justify-content-center d-md-none" v-if="$route.path.includes('/produto')">
                    <nuxt-link to="/carrinho">
                        <b-icon icon="cart" class="icon-minicart-mobile" ></b-icon>
                    </nuxt-link>
                </b-col>

                <b-col cols="2" md="8" lg="6" class="pl-0 pl-md-3" v-if="$wdconfig.modules.search.active">
                    <div class="d-none d-md-block">
                        <form action="/busca/" name="search">
                            <div class="d-flex search">
                                <div class="search-input">
                                    <b-form-input type="search" name="q" placeholder="O que você procura?"></b-form-input>
                                </div>
                                <div class="search-btn">
                                    <button type="submit" aria-label="Buscar">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="35px" height="20px">
                                            <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="d-md-none" @click="showSearch = !showSearch">
                        <div class="search-btn mobile">
                            <button aria-label="Buscar">
                                <div v-if="!showSearch" class="d-flex align-items-center h-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="35px" height="20px">
                                        <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"/>
                                    </svg>
                                </div>
                                <b-icon :icon="showSearch ? 'x' : ''" :class="showSearch ? 'increase-size' : ''"></b-icon>
                            </button>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" class="mt-3 d-md-none" v-if="showSearch">
                    <form action="/busca/" name="search">
                        <div class="d-flex search">
                            <div class="search-input">
                                <b-form-input type="search" name="q" placeholder="O que você procura?"></b-form-input>
                            </div>
                            <div class="search-btn">
                                <button type="submit" aria-label="Buscar">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="35px" height="25px">
                                        <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </form>
                </b-col>
                <b-col lg="3">
                    <div class="justify-content-around account d-none d-lg-flex">
                        <div class="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28.328" height="28.328" viewBox="0 0 28.328 28.328">
                                <path class="a" d="M28.328,14.164A14.164,14.164,0,1,0,14.164,28.328,14.156,14.156,0,0,0,28.328,14.164ZM14.164,1.66a12.506,12.506,0,0,1,10.363,19.5,14.158,14.158,0,0,0-20.725,0A12.506,12.506,0,0,1,14.164,1.66ZM4.836,22.49a12.5,12.5,0,0,1,18.655,0A12.492,12.492,0,0,1,4.836,22.49Zm0,0"/>
                                <path class="a" d="M170.979,72.619a4.985,4.985,0,0,0,4.979-4.979v-1.66a4.979,4.979,0,1,0-9.959,0v1.66A4.985,4.985,0,0,0,170.979,72.619Zm-3.32-6.639a3.32,3.32,0,1,1,6.639,0v1.66a3.32,3.32,0,1,1-6.639,0Zm0,0" transform="translate(-156.816 -57.625)"/>
                            </svg>
                            <div v-if="username !== 'Entre ou cadastre-se'" class="logged">
                                <div> {{ username }}</div>
                                <div>
                                    <nuxt-link to="/conta/meus-dados/">Minha conta</nuxt-link>
                                </div>
                            </div>
                            <div class="login-register" v-else>
                                <nuxt-link to="/login"> {{username}}</nuxt-link>
                            </div>
                        </div>
                        <div>
                            <MiniCart/>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <LazyHydrate when-visible>
            <MegaMenu class="d-none d-lg-block"/>
        </LazyHydrate>
    </div>
</template>
<script>
    import Cookies from 'js-cookie';
    import MiniCart from "~/components/MiniCart";
    import MegaMenu from "~/components/MegaMenu/MegaMenu";
    import utils from "~/mixins/utils";
    import LazyHydrate from 'vue-lazy-hydration';

    export default {
        components: {
            MegaMenu,
            MiniCart,
            LazyHydrate
        },
        mixins: [
            utils
        ],
        data() {
            return {
                isAuth: false,
                location: '',
                showSearch: false
            }
        },
        computed: {
            username() {
                let name = '';
                if (this.$store.getters.isAuth) {
                    try {
                        name = 'Olá ' + this.$store.state.auth.person_name.split(' ')[0]
                        if (process.client) {
                            this.$axios.$post(`?p=erp_pessoas_api_v1&metodo=person_data`, {}, {
                                headers: {
                                    Authorization: 'Bearer ' + this.$store.state.auth.access_token
                                }
                            })
                                .then(res => {
                                    if (res.status !== 'success') {
                                        this.$store.dispatch("logout")
                                    } else {
                                        this.isAuth = true;
                                    }
                                })
                                .catch(e => {
                                    if (e.response.status === 401) {
                                        this.$store.dispatch("logout")
                                    }
                                });
                        }
                    } catch (e) {
                        name = 'Entre ou cadastre-se'
                        this.$store.dispatch("logout")
                        return name
                    }
                    return name
                } else {
                    name = 'Entre ou cadastre-se'
                    return name
                }
            }
        },
        mounted() {
            window.addEventListener('scroll', this.updateScroll)
            if (this.$route.query.location === 'true') {
                sessionStorage.setItem('wdshop_location', this.$route.query.location)
            }
            if (sessionStorage.getItem('wdshop_location') === 'true') {
                this.$wdconfig.locations.map((elem) => {
                    if (elem.link === '/') {
                        Cookies.get('modal_location', elem.name)
                    }
                })
            }
            this.location = Cookies.get('modal_location') || false
        }
    }
</script>
