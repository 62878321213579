import Vue from 'vue';

import {
  BContainer,
  BCol,
  BRow,
  BCollapse,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BFormSelect,
  BFormInput,
  BNavbar,
  BNavbarNav,
  BNavItemDropdown,
  BNavItem,
  BModal,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BIcon,
  BIconCart,
  BIconInfoCircleFill,
  BIconCaretRightFill,
  BIconStarFill,
  BIconHouseFill,
  BIconListUl,
  BIconPersonCircle,
  BIconList,
  BIconX
} from 'bootstrap-vue';

Vue.component('BContainer', BContainer);
Vue.component('BCol', BCol);
Vue.component('BRow', BRow);
Vue.component('BCollapse', BCollapse);
Vue.component('BCard', BCard);
Vue.component('BCardBody', BCardBody);
Vue.component('BCardText', BCardText);
Vue.component('BButton', BButton);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BFormInput', BFormInput);
Vue.component('BNavbar', BNavbar);
Vue.component('BNavbarNav', BNavbarNav);
Vue.component('BNavItemDropdown', BNavItemDropdown);
Vue.component('BNavItem', BNavItem);
Vue.component('BModal', BModal);
Vue.component('BDropdown', BDropdown);
Vue.component('BDropdownItem', BDropdownItem);
Vue.component('BTooltip', BTooltip);
Vue.component('BIcon', BIcon);
Vue.component('BIconCart', BIconCart);
Vue.component('BIconInfoCircleFill', BIconInfoCircleFill);
Vue.component('BIconCaretRightFill', BIconCaretRightFill);
Vue.component('BIconStarFill', BIconStarFill);
Vue.component('BIconHouseFill', BIconHouseFill);
Vue.component('BIconListUl', BIconListUl);
Vue.component('BIconPersonCircle', BIconPersonCircle);
Vue.component('BIconList', BIconList);
Vue.component('BIconX', BIconX);
