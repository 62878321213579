export default function (value) {
    value = value.replace(/[^\d]+/g, '');
    var pesosDigito1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    var pesosDigito2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    return verificacaoGeralCnpj(value) && verificarDigitoCnpj(value, pesosDigito1) && verificarDigitoCnpj(value, pesosDigito2);
}

function verificacaoGeralCnpj(value) {
    var excludeArray = ['00000000000000', '11111111111111', '22222222222222', '33333333333333', '44444444444444', '55555555555555', '66666666666666', '77777777777777', '88888888888888', '99999999999999'];
    if (value === '') return false;
    if (value.length !== 14) return false;
    if (excludeArray.some(function (o) {
        return value === o;
    })) return false;
    return true;
}

function verificarDigitoCnpj(value, pesos) {
    var numbers = value.split('').slice(0, pesos.length); // Soma numeros do CNPJ baseado nos pesos

    var acumuladora = numbers.reduce(function (anterior, atual, index) {
        return anterior + atual * pesos[index];
    }, 0);
    var resto = acumuladora % 11;
    var digito = resto < 2 ? 0 : 11 - resto;
    return parseInt(value[pesos.length]) === digito;
}
