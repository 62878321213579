<template>
    <div class="modal-location">
        <b-modal id="modalLocation">
            <div class="d-flex justify-content-center mb-3">
                <img class="location_image" :src="$wdconfig.default.logo" :alt="$wdconfig.default.title">
            </div>
            <div v-if="$wdconfig.locations.length < 5" class="mb-4">
                <div class="location_title text-center mb-3">
                    {{this.$wdconfig.modules.location.question}}
                </div>
                <div class="d-flex flex-column align-items-start align-items-md-center">
                    <div class="d-flex location_item align-items-baseline" v-for="(city, index) in $wdconfig.locations" :key="index">
                        <svg version="1.1" id="Layer_1" class="icon_map" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                <g>
                                    <g>
                                        <path d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035
                                            c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719
                                            c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z"/>
                                    </g>
                                </g>
                                </svg>
                        <a :href="linkModal(city.link)" target="_top" @click="SaveLocation(city.name, city.link)">
                            <div class="location_name">
                                {{city.name}}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div v-else class="m-4">
                <div class="d-flex justify-content-center align-items-center">
                    <svg version="1.1" id="Capa_1" class="icon_map mr-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 118.783 118.783" style="enable-background:new 0 0 118.783 118.783;" xml:space="preserve">
                                <g>
                                    <path d="M115.97,101.597L88.661,74.286c4.64-7.387,7.333-16.118,7.333-25.488c0-26.509-21.49-47.996-47.998-47.996
                                        S0,22.289,0,48.798c0,26.51,21.487,47.995,47.996,47.995c10.197,0,19.642-3.188,27.414-8.605l26.984,26.986
                                        c1.875,1.873,4.333,2.806,6.788,2.806c2.458,0,4.913-0.933,6.791-2.806C119.72,111.423,119.72,105.347,115.97,101.597z
                                        M47.996,81.243c-17.917,0-32.443-14.525-32.443-32.443s14.526-32.444,32.443-32.444c17.918,0,32.443,14.526,32.443,32.444
                                        S65.914,81.243,47.996,81.243z"/>
                                </g>
                            </svg>
                    <input type="text" class="location_input" v-model="localDigitado" placeholder="Onde você está?">
                </div>
                <div :class="locais.length > 6 ? 'location_boxShadow' : ''" class="location_shadow">
                    <div class="location_box_locations">
                        <div class="location_item d-flex justify-content-md-center align-items-baseline" v-for="(city, index) in locais" :key="index">
                            <svg version="1.1" id="Layer_1" class="icon_map" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                    <g>
                                        <g>
                                            <path d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035
                                                c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719
                                                c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z"/>
                                        </g>
                                    </g>
                                    </svg>
                            <a :href="linkModal(city.link)" target="_top"  @click="SaveLocation(city.name)">
                                <div class="location_name ">
                                    {{city.name}}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import Cookies from 'js-cookie';
export default {
    props: {
    },
    data(){
        return {
            localDigitado: '',
            localidades: [],
            // showModal: '',
        }
    },
    // mounted() {
    //     this.localidades = this.$wdconfig.locations
    //     let locationAtual =  Cookies.get('modal_location')
    //     setTimeout( () => {
    //         if (this.localidades.length > 1 && locationAtual === undefined) {
    //             // this.showModal = 'location_showModal'
    //             // this.show_modal
    //         }
    //     }, 2000)
    // },
    computed: {
        locais: function () {
            let filter = this.localDigitado.trim().toLocaleLowerCase()
            return (this.$wdconfig.locations).filter(function (s) {
                return s.name.toLocaleLowerCase().indexOf(filter) > -1
            })
        }
    },
    methods: {
        // show_modal() {
        //     $('#model-location').modal('show');
        // },
        SaveLocation(location) {
            let location_atual = location
            Cookies.set('modal_location', location_atual, { expires: 7})
        },
        linkModal(link) {
            if (link != '/') {
                return link +'?location=' + true
            }
            if (this.$route.fullPath.includes('location=true')) {
                return this.$route.fullPath
            }
            if (this.$route.fullPath.includes('?')) {
                return this.$route.fullPath +'&location='+true
            }
            return this.$route.fullPath +'?location='+true
        }
    }
};
</script>
