import Vue from 'vue'

Vue.mixin({
    computed: {
        $wdconfig: {
            get: function () {
                return this.$store.state.config
            },
            set: function (newValue) {
                this.$store.commit('setConfig', newValue)
            }
        },
        $host() {
            return this.$config.baseURL.slice(-1) === '/' ? this.$config.baseURL.slice(0, -1) : this.$config.baseURL
        },
        $base_api() {
            return this.$config.baseApiURL
        }
    }
});
