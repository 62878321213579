<template>
    <div class="mini-cart" @mouseover="triggerIn(); hover = true" @mouseleave="triggerOut(); hover = false">
        <nuxt-link to="/carrinho" aria-label="Carrinho">
            <b-icon icon="cart" class="icon-minicart" ></b-icon>
            <p class="number_orders" v-if="$store.state.order.order.quantidade_total > 0">{{ $store.state.order.order.quantidade_total }}</p>
        </nuxt-link>
        <div v-clickout="hidden">
            <transition name="fade">
                <div class="view-products-cart" v-show="openCart">
                    <div class="title"> Meu carrinho de compras</div>
                    <hr class="mt-2">
                    <div class="mini-cart-list">
                        <b-col cols="12" class="product-in-cart mb-2" v-for="product in $store.state.order.order.produtos" :key="product.code">
                            <b-row class="mt-3">
                                <b-col lg="5">
                                    <div class="aspect-ratio" :class="getClassAspectRatio">
                                        <div class="aspect-content">
                                            <nuxt-link :to="product.route">
                                                <LazyLoad image_class="object-fit-cover" :photo="imageUrl(product.foto)" :alt="product.nome" :title="product.nome"/>
                                            </nuxt-link>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col lg="7" class="pl-0">
                                    <div class="name">
                                        <nuxt-link :to="product.route">{{ product.nome }}</nuxt-link>
                                    </div>
                                    <div class="price mt-1">{{ parseFloat(product.quantidade).toFixed(product.unidade_precisao || 0) }}x {{ product.valor_unitario | currency }}</div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between mt-1" v-if="$store.state.order.order.quantidade_total">
                        <div class="subtotal-text"> Subtotal</div>
                        <div class="subtotal-price">{{ $store.state.order.order.valor_total | currency }}</div>
                    </div>
                    <div v-else>
                        <div class="subtotal-text text-center"> Seu carrinho está vazio</div>
                    </div>
                    <div class="d-flex justify-content-center mt-3" v-if="$store.state.order.order.quantidade_total > 0" @click.prevent="openCart = false">
                        <nuxt-link to="/carrinho" class="btn-default btn-square btn-add-cart w-100">
                            IR PARA O CARRINHO
                        </nuxt-link>
                    </div>
                </div>
            </transition>
        </div>
        <b-modal ref="modal-mini-cart" :centered="true" v-model="openModal" hide-footer hide-header-close title="Produto adicionado ao carrinho de compras!" body-class="pt-0" v-if="$store.state.order.order.quantidade_total > 0">
            <div class="mini-cart-list">
                <b-col cols="12" class="product-in-cart mb-2">
                    <b-row class="mt-3">
                        <b-col cols="5">
                            <div class="aspect-ratio" :class="getClassAspectRatio">
                                <div class="aspect-content">
                                    <nuxt-link :to="lastProduct.route">
                                        <LazyLoad image_class="object-fit-cover" :photo="imageUrl(lastProduct.foto)" :alt="lastProduct.nome" :title="lastProduct.nome"/>
                                    </nuxt-link>
                                </div>
                            </div>
                        </b-col>
                        <b-col class="pl-0 d-flex align-items-center">
                            <div class="name">
                                {{ lastProduct.nome }}
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </div>
            <b-row>
                <b-col sm="6">
                    <b-button @click="$refs['modal-mini-cart'].hide()" class="btn-default btn-square btn-keep-buying w-100 d-block my-2 px-2">
                        CONTINUAR COMPRANDO
                    </b-button>
                </b-col>
                <b-col sm="6">
                    <span @click.prevent="openModal = false" class="my-2 d-block">
                        <nuxt-link to="/carrinho" class="btn-default btn-square w-100 d-block btn-small px-2">
                            IR PARA O CARRINHO
                        </nuxt-link>
                    </span>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import utils from '~/mixins/utils';
    import order from '~/mixins/order';
    import LazyLoad from '~/components/LazyLoad';

    export default {
        components: {
            LazyLoad
        },
        mixins: [
            utils,
            order
        ],
        data() {
            return {
                openCart: false,
                openModal: false,
                hover: false,
            }
        },
        computed: {
            lastProduct() {
                if (this.$store.state.order.order.produtos.length) {
                    return this.$store.state.order.order.produtos[0]
                }
                return {}
            }
        },
        methods: {
            triggerIn() {
                setTimeout(() => {
                    if (this.hover) {
                        this.openCart = true
                    }
                }, 100)
            },
            triggerOut() {
                setTimeout(() => {
                    if (this.hover === false) {
                        this.openCart = false
                    }
                }, 500)
            },
            hidden() {
                this.openCart = false
            }
        },
        mounted() {
            this.$nuxt.$on("openMiniCart", () => {
                this.openCart = true
                this.openModal = window.innerWidth <= 991
                this.hover = true
            });
        }
    }
</script>
