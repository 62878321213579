export default function ({app, req, res, query, error, store}) {
    if (!!store.state.config.modules.general.site_offline) {
        res.setHeader('Content-Type', 'text/html');
        res.end('');
    }
    let isMaintenance = store.state.config.modules.general.site_mode === 'maintenance';
    let isConstruction = store.state.config.modules.general.site_mode === 'construction';
    if (isMaintenance || isConstruction) {
        let constru = query.constru || query.construcao
        if (constru === 'sim') {
            app.$cookies.set('maintenance_mode', 'true')
            isMaintenance = false;
            isConstruction = false;
        }
        if (constru === 'nao') {
            app.$cookies.set('maintenance_mode', null)
        }
        if (app.$cookies.get('maintenance_mode') && constru !== 'nao') {
            isMaintenance = false;
            isConstruction = false;
        }
        if (isConstruction) {
            return error({
                statusCode: 200,
                title: 'Site em construção',
                message: store.state.config.modules.general.site_mode_construction_custom_message || 'Site em construção',
                image: store.state.config.modules.general.site_mode_construction_custom_image || ''
            })
        }
        if (isMaintenance) {
            return error({
                statusCode: 503,
                title: 'Site em manutenção',
                message: store.state.config.modules.general.site_mode_maintenance_custom_message || 'Site em manutenção',
                image: store.state.config.modules.general.site_mode_maintenance_custom_image || ''
            })
        }
    }
}
