<template>
    <div class="header pt-1 pb-1">
        <b-container>
            <div class="roof">
                <b-row>
                    <b-col cols="12" lg="7">
                        <TopBanner :area="2012"/>
                    </b-col>
                    <b-col md="5" class="d-none d-lg-block">
                        <div class="d-flex justify-content-end align-items-baseline">
                            <div v-if="$wdconfig.locations.length">
                                <b-button class="open-modal-location p-0" v-b-modal.modalLocation>Você está em: {{ location }}</b-button>
                            </div>
                            <div class="vertical-line" v-if="$wdconfig.locations.length">|</div>
                            <div class="menu-user" v-for="item in menu" :key="item.id">
                                <a :href="item.href" :target="item.target" v-html="item.name"></a>
                            </div>
                            <div class="menu-user" v-if="$store.getters.isAuth">
                                <nuxt-link to="/logout">Sair</nuxt-link>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-container>
    </div>
</template>

<script>
    import Cookies from 'js-cookie';
    import TopBanner from "~/components/TopBanner";

    export default {
        components: {
            TopBanner
        },
        data() {
            return {
                location: '',
                menu: []
            }
        },
        mounted() {
            if (this.$route.query.location === 'true') {
                sessionStorage.setItem('wdshop_location', this.$route.query.location)
            }
            if (sessionStorage.getItem('wdshop_location') === 'true') {
                this.$wdconfig.locations.map((elem) => {
                    if (elem.link === '/') {
                        Cookies.get('modal_location', elem.name)
                    }
                })
            }
            this.location = Cookies.get('modal_location') || false
        },
        async fetch() {
            await this.$axios.$get(`/api/v1/menu/?area=5`).then((res) => {
                this.menu = res.data
            }).catch((e) => {
                console.error(e)
            })
        }
    }
</script>
