<template>
    <component :is="tag" class="megamenu-view">
        <div class="d-flex align-items-center mb-2">
            <div class="title-category">{{ item.name }}</div>
        </div>
        <b-row>
            <b-col class="megamenu-view-list" :data-n-cols="nCols">
                <div v-for="menu in submenus" :key="menu.code" :class="{'level-2': menu.level === 1, 'level-3 ml-2': menu.level === 2, 'mb-1': menu.break }">
                    <nuxt-link :to="menu.route" v-if="menu.route">
                        {{ menu.name }}
                    </nuxt-link>
                </div>
                <nuxt-link :to="'/categoria/' + item.alias" class="see-all-categories mt-1" v-if="isLimited">
                    + categorias
                </nuxt-link>
            </b-col>
        </b-row>
    </component>
</template>

<script>
    export default {
        data() {
            return {
                isLimited: false
            }
        },
        props: {
            item: {
                default: () => {
                }
            },
            tag: {
                default: 'div'
            },
            maxCols: {
                default: 4
            },
            maxItemsCol: {
                default: 12
            }
        },
        computed: {
            nCols() {
                return Math.min(Math.max(Math.floor(this.item.subitems.length / 4), 1), 4)
            },
            submenus() {
                let flatten = [];
                const limit = this.maxItemsCol * this.maxCols
                try {
                    this.item.subitems.forEach(el => {
                        el['route'] = this.item.route + (this.item.route.slice(-1) !== '/' ? '/' : '') + el.alias
                        el['level'] = 1
                        el['break'] = false
                        flatten.push(el)
                        if (el.subitems.length) {
                            el.subitems.forEach(el2 => {
                                el2['route'] = el['route'] + (el['route'].slice(-1) !== '/' ? '/' : '') + el2.alias
                                el2['level'] = 2
                                el2['break'] = false
                                flatten.push(el2)
                            })
                            flatten[flatten.length - 1]['break'] = true
                        }
                    });
                    if (flatten.length > limit) {
                        this.isLimited = true
                        flatten = flatten.slice(0, limit - 1)
                    }
                } catch (e) {
                    console.error(e)
                }
                return flatten;
            }
        }
    }
</script>
