<template>
    <component :is="tag" class="megamenu-view">
        <div class="d-flex align-items-center mb-2">
            <div class="title-category">{{ item.name }}</div>
            <div class="pl-4" v-if="item.alias">
                <nuxt-link :to="'/categoria/' + item.alias" class="see-all-categories">ver todas categorias</nuxt-link>
            </div>
        </div>
        <b-row>
            <b-col class="megamenu-view-list" :data-n-cols="nCols">
                <div v-for="category in subcategories" :key="category.code" :class="{'level-2': category.level === 1, 'level-3 ml-2': category.level === 2, 'mb-1': category.break }">
                    <nuxt-link :to="category.url">
                        {{ category.name }}
                    </nuxt-link>
                </div>
                <nuxt-link :to="'/categoria/' + item.alias" class="see-all-categories mt-1" v-if="isLimited">
                    + categorias
                </nuxt-link>
            </b-col>
            <ShowcaseProductCategories :product="product" :megamenu="true" v-if="showProduct"/>
        </b-row>
    </component>
</template>

<script>
    import ShowcaseProductCategories from "~/components/ShowcaseProductCategories";

    export default {
        components: {ShowcaseProductCategories},
        data() {
            return {
                limit: 1,
                product: {
                    code: 0
                },
                isLimited: false
            }
        },
        props: {
            item: {
                default: () => {}
            },
            tag: {
                default: 'div'
            },
            maxCols: {
                default: 4
            },
            maxItemsCol: {
                default: 12
            }
        },
        computed: {
            nCols() {
                let nCols = Math.floor(this.subcategories.length / this.maxCols)
                if (nCols < 1) {
                    nCols = 1
                }
                if (nCols > this.maxCols) {
                    nCols = this.maxCols
                }
                return nCols
            },
            subcategories() {
                let flatten = [];
                let total = 0;
                try {
                    const limit = this.maxItemsCol * this.maxCols
                    this.item.subitems.forEach(el => {
                        total++
                        el['url'] = '/categoria/' + this.item.alias + '/' + el.alias
                        el['level'] = 1
                        el['break'] = false
                        flatten.push(el)
                        if (el.subitems.length) {
                            el.subitems.forEach(el2 => {
                                total++
                                el2['url'] = '/categoria/' + this.item.alias + '/' + el.alias + '/' + el2.alias
                                el2['level'] = 2
                                el2['break'] = false
                                flatten.push(el2)
                            })
                            flatten[flatten.length - 1]['break'] = true
                        }
                    });
                    if (flatten.length > limit) {
                        this.isLimited = true
                        flatten = flatten.slice(0, limit - 1)
                    }
                } catch (e) {
                    console.error(e)
                }
                return flatten;
            },
            showProduct() {
                try {
                    return this.nCols <= 2 && this.item.code && this.$wdconfig.modules.showcase.menu_categories
                } catch (e) {
                    return false
                }
            }
        },
        async fetch() {
            if (this.showProduct) {
                await this.$axios.$get(`/?p=ecommerce_produtos_api_v1&category_code=${this.item.code}&in_showcase=${this.$wdconfig.modules.showcase.menu_categories}&limit=${this.limit}&thumb=sim&x=340`).then((res) => {
                    if (res.status === 'success' && res.data && res.data.length) {
                        this.product = res.data[0]
                    }
                }).catch((e) => {
                    console.error(e)
                })
            }
        },
        fetchOnServer: false
    }
</script>
