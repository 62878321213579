<template>
    <div>
        <b-button class="collapse-menu-btn collapse-menu-name w-100 d-flex justify-content-between" :class="{'collapsed': !visible, 'not-collapsed': visible}" @click.prevent="trigger" :href="route">
            {{ item.name }}
            <div v-if="item.subitems.length">
                <svg class="collapse-menu-icon" xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 51.431 73">
                    <defs>
                        <filter x="0" y="0" width="51.431" height="73" filterUnits="userSpaceOnUse">
                            <feOffset dy="3" input="SourceAlpha"/>
                            <feGaussianBlur stdDeviation="3" result="blur"/>
                            <feFlood flood-opacity="0.161"/>
                            <feComposite operator="in" in2="blur"/>
                            <feComposite in="SourceGraphic"/>
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)">
                        <path d="M1.737,10.125a5.931,5.931,0,0,1,8.388-8.388L31.694,23.306a5.931,5.931,0,0,1,0,8.388L10.125,53.263a5.931,5.931,0,1,1-8.388-8.388L19.112,27.5Z"/>
                    </g>
                </svg>
            </div>
        </b-button>
        <b-collapse v-model="visible" v-if="item.subitems.length" class="collapse-container">
            <CategoriesMobileItem :item="subitem" v-for="subitem in item.subitems" :key="subitem.code" :parent_route="route"/>
        </b-collapse>
    </div>
</template>

<script>
    export default {
        name: 'CategoriesMobileItem',
        props: {
            item: {
                required: true,
                type: Object,
                default: () => {}
            },
            parent_route: {
                required: false,
                type: String,
                default: ''
            }
        },
        data() {
            return {
                visible: false
            }
        },
        computed: {
            route() {
                return this.parent_route + '/' + this.item.alias
            }
        },
        methods: {
            trigger() {
                if (this.item.subitems.length > 0) {
                    this.visible = !this.visible
                } else {
                    this.$router.push(this.route)
                }
            }
            // tagProps(item) {
            //     if (item.subitems.length <= 0) {
            //         return {
            //             to: this.route
            //         }
            //     }
            // }
        }
    }
</script>
