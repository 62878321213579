<template>
    <nuxt/>
</template>

<script>
    export default {
        head() {
            return {
                style: [
                    {
                        hid: 'site-theme',
                        cssText: `:root {${this.$store.getters.cssTheme}}`,
                        type: 'text/css'
                    }
                ]
            }
        }
    }
</script>
