import Vue from 'vue'

Vue.mixin({
    data: function () {
        return {

        }
    },
    methods:{
        gtagConfig(GAID) {
            ;(function (i, s, o, g, r, a, m) {
                i['GoogleAnalyticsObject'] = r
                ;(i[r] =
                i[r] ||
                function () {
                    ;(i[r].q = i[r].q || []).push(arguments)
                }),
                (i[r].l = 1 * new Date())
                ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
                a.async = 1
                a.src = g
                m.parentNode.insertBefore(a, m)
            })(
                window,
                document,
                'script',
                'https://www.googletagmanager.com/gtag/js?id='+GAID,
                'ga'
            )
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', GAID, {'anonymize_ip': true});
        },
        gtagEvent(track, event, params, counter = 10) {
            setTimeout(() => {
                if (counter <= 0) {
                    return
                }
                counter--;
                try {
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag(track, event, params);
                }
                catch(e) {
                    setTimeout(() => {
                        this.gtagEvent(track, event, params, counter);
                    },500);
                }
            },500);
        },
    },
});
