export default {
    computed: {
        getListSocials() {
            let socials = []
            for (var prop in this.$wdconfig.default.social) {
                let obj = this.$wdconfig.default.social[prop]
                if (prop !== 'whatsapp' && obj.active === 'true') {
                    socials.push(obj)
                }
            }
            return socials
        }
    }
}
